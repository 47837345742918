export const ecosystem = [
  {
    groupKey: 'ClientAndMissions',
    name: 'Clients et missions',
    icon: 'loading',
    mini: false,
    order:1
  },
  {
    groupKey: 'Teams',
    name: 'Équipe',
    icon: 'social',
    mini: false,
    order:2
  },
  {
    groupKey: 'Purchases',
    name: 'Achats',
    icon: 'euro',
    mini: false,
    order:3
  },
  {
    groupKey: 'ActivitiesManagement',
    name: "Pilotage d'activité",
    icon: 'line-chart',
    mini: false,
    order: 4
  },
  {
    groupKey: 'PersonalSpace',
    name: 'Espace personnel',
    icon: 'user',
    mini: true,
    order: 5
  },
  {
    groupKey: 'Trades',
    name: 'Métiers',
    icon: 'dashboard',
    mini: true,
    order:6
  }
]
